import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			q: {},
			queryForm: {},
			form: {},
			namespace: ''
		}
	},
	methods: {
		searchEvent() {
			this.q = {
				...this.queryForm
			}
			this.page.current = 1
			this.getList()
		},
		handleCommand(command) {
			if (this.namespace === '') return
			const ids = this.multipleSelection.map(v => v.id)
			const active = command === 'active' ? 'true' : 'false'
			this.adminLayout.showLoading()
			const payload = {
				ids,
				active
			}
			this.$store.dispatch(`${this.namespace}/activeItems`, payload)
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
				})
				.catch(err => {
					this.adminLayout.hideLoading()
				})
		},
		handleList(content) {
			this.tableData = content
		},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			const payload = {
				params: {
					...this.page,
					...this.q
				},
				trigger
			}
			return this.$store.dispatch(`${this.namespace}/getList`, payload)
		},
		changeActive(item) {
			if (this.namespace === '') return
			const {
				id,
				active
			} = item
			// publish or uppublish item
			this.adminLayout.showLoading()
			const payload = {
				id,
				active
			}
			this.$store.dispatch(`${this.namespace}/activeItem`, payload)
			.then(res => {
				this.adminLayout.hideLoading()
				this.getList()
			})
			.catch(err => {
				this.$showErrMsg(err)
				this.adminLayout.hideLoading()
			})
		},
		doCreateOrUpdate() {
			if (this.namespace === '') return
			this.$refs.form.validate(v => {
				if (v) {
					this.adminLayout.showLoading(true)
					const {
						id
					} = this.form
					if (id) {
						const payload = {
							id,
							body: {
								...this.form
							}
						}
						this.$store.dispatch(`${this.namespace}/updateItem`, payload)
						.then(res => {
							this.$showSuccess('更新成功')
							this.adminLayout.hideLoading()
							this.visible.add = false
							this.getList()
						})
						.catch(err => {
							this.$showErrMsg(err)
							this.adminLayout.hideLoading()
						})
						return
					}
					const payload = {
						...this.form,
						active: true
					}
					this.$store.dispatch(`${this.namespace}/newItem`, payload)
					.then(res => {
						this.$showSuccess('上传成功')
						this.adminLayout.hideLoading()
						this.visible.add = false
						this.getList()
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
				}
			})
		},
	}
}
