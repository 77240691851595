<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect">
		<el-table-column type="selection" width="55">
		</el-table-column>
		<el-table-column label="站点" prop="website" min-width="120">
		</el-table-column>
		<el-table-column label="Account ID" prop="accountId" min-width="150">
		</el-table-column>
		<el-table-column label="Account Name" prop="accountName" min-width="150">
		</el-table-column>
		<el-table-column label="Campaign ID" prop="campaignId" min-width="150">
		</el-table-column>
		<el-table-column label="Campaign Name" prop="campaignName" min-width="200">
		</el-table-column>
		<el-table-column label="国家" prop="country" min-width="100">
		</el-table-column>
		<el-table-column label="语言" prop="language" min-width="100">
		</el-table-column>
		<el-table-column label="发布类型" prop="publishType" min-width="150">
		</el-table-column>
		<el-table-column label="发布状态" min-width="150">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style>
</style>
