<template>
	<el-card>
		<div class="d-flex flex-row flex-wrap">
			<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;getList()" :clearable="false"></form-item-sites>
			<form-item label="BM ID:" style="width:250px;">
				<el-input v-model="queryForm.bmId" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item label="Account Name:" style="width:250px;">
				<el-input v-model="queryForm.accountName" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item label="国家:" style="width:200px;">
				<el-input v-model="queryForm.country" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item label="语言:" style="width:200px;">
				<el-input v-model="queryForm.language" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item label="Campaign ID:" style="width:250px;">
				<el-input v-model="queryForm.campaignIds" placeholder="请输入" size="mini"></el-input>
			</form-item>
			<form-item-publishtypes :with-layout="true" v-model="queryForm.publishType" platform="gg" clearable></form-item-publishtypes>
			<form-item label="发布状态:" style="width:230px;">
				<el-select v-model="queryForm.active" size="mini" clearable>
					<el-option label="开" :value="true"></el-option>
					<el-option label="关" :value="false"></el-option>
				</el-select>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
			</form-item>
			<form-item>
				<el-button type="primary" size="mini" @click="visible.add=true">添加账户信息</el-button>
			</form-item>
			<form-item>
				<upload-button action="/adms_api_v0/google/import" accept=".csv" title="上传账户信息" tooltip="csv文件,表头为'website,account,campaign,publishType'" @callback="getList"></upload-button>
			</form-item>
		</div>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
			      <span class="text-primary">
			        批量操作
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="active">发布</el-dropdown-item>
			        <el-dropdown-item command="deactive">取消发布</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
		</alert>
		<account-info-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange" @changePublish="changeActive" @editRow="editRow"></account-info-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true">
				<el-form-item-sites :abbr="false" v-model="form.channel" label-width="60px" prop="channel"></el-form-item-sites>
				<el-form-item label="Account ID:" label-width="100px" prop="accountId">
					<el-input v-model="form.accountId" placeholder="请输入Account ID"></el-input>
				</el-form-item>
				<el-form-item label="Campaign ID:" label-width="110px" prop="campaignId">
					<el-input v-model="form.campaignId" placeholder="请输入Campaign ID, 多个用,分割"></el-input>
				</el-form-item>
				<el-form-item label="发布类型:" label-width="90px" prop="publishType">
					<form-item-publishtypes :with-layout="false" v-model="form.publishType" platform="gg" add-class="w-100"></form-item-publishtypes>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapState,
		mapActions
	} from 'vuex'
	import AccountInfoTable from '@/components/google-accounts/info-table.vue'
	import config from './minixs/index.js'
	const InitialForm = {
		channel: '',
		accountId: '',
		campaignId: '',
		publishType: 'UDA'
	}
	export default {
		mixins:[config],
		components: {
			AccountInfoTable
		},
		data() {
			return {
				queryForm: {
					channel: '',
					bmId: '',
					accountName: '',
					country: '',
					language: '',
					campaignIds: '',
					publishType: '',
					active: ''
				},
				tableData: [],
				q: {
					channel: ''
				},
				visible: {
					add: false
				},
				form: {
					...InitialForm
				},
				rules: {
					channel: [{
						required: true,
						message: '站点不能为空'
					}],
					accountId: [{
						required: true,
						message: 'Account ID不能为空'
					}],
					campaignId: [{
						required: true,
						message: 'Campaign ID不能为空'
					}],
					publishType: [{
						required: true,
						message: '发布类型不能为空'
					}]
				},
				page: {
					current: 1,
					size: 20,
					sizes: [5, 10, 15, 20],
					total: 0
				},
				fetchOnCreated: false,
				namespace: 'google-accounts'
			}
		},
		methods: {
			searchEvent() {
				const campaignIds = this.queryForm.campaignIds.trim().split(',').map(v => v.trim())
				this.q = {
					...this.queryForm,
					campaignIds
				}
				this.page.current = 1
				this.getList()
			},
			editRow(item) {
				this.form = {
					...InitialForm,
					...item
				}
				this.visible.add = true
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = {
						...InitialForm
					}
				}
			}
		}
	}
</script>

<style>
</style>
